import service from '@/until/api'

export function _TableList(data) {
    return service({
        url: '/report-data/canDataCollect/canDataList/' + JSON.parse(sessionStorage.getItem("Cansize")) + "/" + JSON.parse(sessionStorage.getItem("Canpage")),
        method: 'POST',
        data
    })
}
export function _VeDataList(data) {
    
    return service({
        url: '/report-data/canDataCollect/carDataDetail/' + JSON.parse(sessionStorage.getItem("Vesize")) + "/" + JSON.parse(sessionStorage.getItem("Vepage")),
        method: 'POST',
        data
    })
}
