<template>
  <div>
    <div>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="list.parkID"
            :clearable="true"
            placeholder="请选择园区"
            class="centerSelInput"
            @change="SearchPageData"
          >
            <el-option
              v-for="item in ParkOption"
              :key="item.val"
              :label="item.text"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="list.carType"
            :clearable="true"
            placeholder="请选择车辆类型"
            class="centerSelInput"
            @change="SearchPageData"
          >
            <el-option
              v-for="item in CarTypeOption"
              :key="item.text"
              :label="item.text"
              :value="item.text"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            :clearable="true"
            v-model="list.carNumber"
            placeholder="请输入车辆编号"
            class="centerInput"
            @clear="SearchPageData"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="list.date"
            :clearable="true"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="centerDatepicker"
            @change="SearchPageData"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn" type="button" @click="getTableDate">搜索</button>
        </el-form-item>
      </el-form>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableDate"
      :loading="loading"
    />

    <el-drawer
      :visible.sync="drawerShow"
      :before-close="handleClose"
      size="85%"
      destroy-on-close
    >
      <div class="acquisitionHeadF">
        <div class="acquisitionHead">
          <div>
            车辆编号：<span>{{ item.carNumber }}</span>
          </div>
          <div>
            车辆类型：<span>{{ item.carTypeStr }}</span>
          </div>
          <div>
            车辆子类型：<span>{{ item.carChildTypeStr }}</span>
          </div>
          <div>
            车辆所属组织：<span>{{ item.parkName }}</span>
          </div>
        </div>
      </div>
      <div class="carCan">
        <div class="uploadRecord">
          <div>车辆CAN数据上传记录</div>
          <div class="mt20">
            <el-form :inline="true">
              <el-form-item>
                <el-date-picker
                  class="centerDatepicker"
                  v-model="listTwo.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="getDetailDate"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <button class="centerCzbtn">搜索</button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="tableDataTwo" style="width: 100%">
            <el-table-column type="index" label="编号"> </el-table-column>
            <el-table-column prop="date" label="日期"> </el-table-column>
            <el-table-column prop="beginTime" label="开始上传时间"> </el-table-column>
            <el-table-column prop="endTime" label="结束结束时间"> </el-table-column>

            <template slot="empty" class="emptyTem">
              <img :src="emptyImg" alt="" srcset="" />
              <div>暂无数据</div>
            </template>
          </el-table>
        </div>
        <div class="packetDownload">
          <div>车辆CAN数据包下载</div>
          <div class="mt20">
            <el-form :inline="true">
              <el-form-item>
                <el-date-picker
                  class="centerDatepicker"
                  v-model="listThe.date"
                  type="daterange"
                  range-separator="-"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <button class="centerBtn">生成文件</button>
              </el-form-item>
            </el-form>
          </div>
          <div class="historicalFile">
            <!-- <div class="historyHead">当前生成文件</div> -->
            <div class="historyHead">历史文件记录</div>
            <div class="flex" v-for="item in tableDataTh" :key="item">
              <p>{{ item.createdAt }}</p>
              <p>{{ item.fileName }}</p>
              <p class="download">下载</p>
            </div>
            <el-empty
              description="暂无数据"
              :image="emptyImg"
              v-if="tableDataTh.length <= 0"
            ></el-empty>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { _TableList, _VeDataList } from "@/api/canAcquisition";
import { _CarTypeOption, _ParkOption } from "../../api/global";
export default {
  components: {
    Table,
  },
  data() {
    return {
      emptyImg: require("../../assets/images/empty.png"), //暂无数据图片
      loading: false,
      drawerShow: false,
      total: 0,
      item: "",
      tableData: [],
      tableDataTwo: [],
      tableDataTh: [],
      ParkOption: [],
      CarTypeOption: [],
      columns: [
        {
          label: "车辆编号",
          prop: "carNumber",
        },
        {
          label: "车辆类型",
          prop: "carTypeStr",
        },
        {
          label: "车辆子类型",
          prop: "carChildTypeStr",
        },

        {
          label: "车辆所属组织",
          prop: "parkName",
        },
        {
          label: "最近一次上电",
          prop: "lastUpDate",
        },
        {
          label: "最近一次下电",
          prop: "lastDownDate",
        },
        {
          label: "最近一次CAN上传",
          prop: "lastUpCanDate",
          width: 160,
        },
        {
          label: "操作",
          // fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.drawerShow = true;
                      this.item = params.row;
                      this.listTwo.carNumber = params.row.carNumber;
                      this.getDetailDate();
                    },
                  },
                },
                "数据采集"
              ),
            ]);
          },
        },
      ],
      list: {
        parkID: "", //园区id
        carType: "", //车辆类型ID
        carNumber: "", //车辆编号
        beginDate: "",
        endDate: "",
        page: 1, //页数
        pageSize: 10, //页记录数
      },
      listTwo: {
        carNumber: "", //车辆编号
        beginDate: "",
        endDate: "",
        date: "",
      },
      listThe: {
        date: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getTableDate();
    this.GetParkOption();
    this.GetCarTypeOption();
  },
  methods: {
    //获取分页数据
    async getTableDate(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.page.currentPage = pagination.page;
          this.page.pageSize = pagination.limit;
        }
      }
      window.sessionStorage.setItem("Canpage", this.page.currentPage);
      window.sessionStorage.setItem("Cansize", this.page.pageSize);
      _TableList(this.list).then((res) => {
        if (res.success == true) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
        }
        this.loading = false;
      });
    },
    //获取CAN数据上传记录
    async getDetailDate(pagination) {
      this.loading = true;
      window.sessionStorage.setItem("Vepage", this.page.currentPage);
      window.sessionStorage.setItem("Vesize", this.page.pageSize);
      if (this.listTwo.date === null) {
        this.listTwo.date = [];
        this.listTwo.beginDate = "";
        this.listTwo.endDate = "";
      }

      if (this.listTwo.date.length === 2) {
        this.listTwo.beginDate = this.listTwo.date[0];
        this.listTwo.endDate = this.listTwo.date[1];
      }
      _VeDataList(this.listTwo).then((res) => {
        if (res.success == true) {
          this.tableDataTwo = res.data.list;
          if (this.tableDataTwo.length > 0) {
            this.getDetailDateTwo();
          }
        } else {
        }
        this.loading = false;
      });
    },
    //测试数据
    async getDetailDateTwo() {
      this.tableDataTh = [
        {
          createdAt: "2021-10-25 15:31",
          fileName: "DF001-2021-10-25-15-15.asc",
          downloadUrl: "",
        },
        {
          createdAt: "2021-10-25 17:12",
          fileName: "DF001-2021-10-25-15-17.asc",
          downloadUrl: "",
        },
        {
          createdAt: "2021-10-25 18:00",
          fileName: "DF001-2021-10-25-15-18.asc",
          downloadUrl: "",
        },
      ];
    },
    //获取园区选项
    async GetParkOption() {
      let res = await _ParkOption();

      if (res.success) {
        this.ParkOption = res.data;
      }
    },
    //获取车辆类型选项
    async GetCarTypeOption() {
      let res = await _CarTypeOption();

      if (res.success) {
        this.CarTypeOption = res.data;
      }
    },
    //搜索
    async SearchPageData() {
      await this.getTableDate();
    },
    //关闭抽屉
    handleClose() {
      this.drawerShow = false;
    },
  },
};
</script>

<style lang="less">
.acquisitionHeadF {
  display: flex;
}

.acquisitionHead {
  color: #666;
  font-size: 14px;
  background-color: #fff;
  display: flex;
  height: 36px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 12px;
}

.acquisitionHead > div {
  margin-right: 40px;
}

.acquisitionHead span {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.carCan {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.uploadRecord {
  width: 45%;
  padding: 20px 14px;
  background-color: #fff;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;

  thead {
    background: #f5f8fa !important;
  }

  .has-gutter > tr > th {
    background: #f5f8fa;
  }

  .el-table__empty-block {
    min-height: 300px;
    margin-top: 20px;
  }
}

.historyHead {
  height: 48px;
  background: #f5f8fa;
  padding-left: 20px;
  line-height: 48px;
  color: #909399;
  font-size: 16px;
  font-weight: 550;
}

.historicalFile {
  .el-empty {
    padding: 20px 0px;

    img {
      width: 90px;
      height: 100px;
    }

    p {
      margin-top: 20px;
    }
  }
}

.packetDownload {
  width: 53%;
  padding: 20px 30px;
  background-color: #fff;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;
}
.flex {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px dashed #999999;
  p {
    padding: 0px 40px;
  }
  .download {
    color: #1683d9;
  }
}
</style>
