import service from '@/until/api'

export function _ParkOption() {
    return service({
        url: '/report-data/common/district',
        method: 'GET',
    })
}

export function _CarTypeOption() {
    return service({
        url: '/report-data/common/carType',
        method: 'GET',
    })
}

export function _getParkList() {
  return service({
    url: '/data-manage/board/parks',
    method: 'GET',
  })
}